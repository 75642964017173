body {
  background: #7474BF;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #348AC7, #7474BF);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #348AC7, #7474BF); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ui.center.aligned.header, .ui.centered.header {
  text-align: center;
  font-family: 'Roboto Mono', monospace;

}

.ui.center.aligned.header.top {
  font-weight: bolder;
}

.youtube-responsive-container {
  position:relative;
  padding-bottom:56.25%;
  padding-top:30px;
  height:0;
  overflow:hidden;
  }
  
  .youtube-responsive-container iframe, .youtube-responsive-container object, .youtube-responsive-container embed {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  }

  div.link {
display: grid;
}

  